import { Route, Routes } from '@remix-run/react';
import { Navigate } from '@remix-run/react';
import React from 'react';

const AdminMinigame = React.lazy(() => import('./Minigame'));
const AdminToolkit = React.lazy(() => import('./Toolkit'));
const Organization = React.lazy(() => import('./Organization'));
const AdminPrograms = React.lazy(() => import('./Programs'));

export { AdminGamePackWrapper } from './GamePack';

// eslint-disable-next-line import/no-default-export
export default function Home(): JSX.Element {
  return (
    <Routes>
      <Route index element={<Navigate to='/admin/minigames' />} />
      <Route path={`minigames/*`} element={<AdminMinigame />} />
      <Route path={`toolkit/*`} element={<AdminToolkit />} />
      <Route path={`/organizations/*`} element={<Organization />} />
      <Route path={`/programs/*`} element={<AdminPrograms />} />
    </Routes>
  );
}
