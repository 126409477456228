import { type ReactNode } from 'react';

import { PortalLink } from '../../components/PortalContext';
import { Wrapper } from '../GamePack';
import { AdminView } from './AdminView';

export function AdminGamePackWrapper(props: { children?: ReactNode }) {
  return (
    <AdminView
      search={{
        targetPathname: '/admin/gamepacks/search',
        placeholder: 'Search Game Packs',
      }}
      className='bg-library-2023-07'
    >
      <Wrapper
        mode='admin'
        routePrefix={PortalLink.AdminGamePack}
        editRoutePrefix={PortalLink.AdminGamePack}
        pageType='default'
        breadcrumbLabel='Game Packs'
      >
        {props.children}
      </Wrapper>
    </AdminView>
  );
}
